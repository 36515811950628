import Contact from "../components/contact"

import FullCalendar from "@fullcalendar/react"
import "@fullcalendar/daygrid/main.css"
import huLocale from "@fullcalendar/core/locales/hu"
import Layout from "../components/layout"
import "@fullcalendar/timegrid/main.css"
import React, { useState, useEffect } from "react"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import Seo from "../components/seo"
import "../styles/calendar.css"

export default function Calendar() {
  // In Gatsby we need to check the window object because these variables are not available within the build process
  let view
  if (typeof window !== "undefined") {
    view = window.innerWidth <= 600 ? "dayGridDay" : "timeGridWeek"
  }
  const SCOPES = "https://www.googleapis.com/auth/calendar.readonly"
  const [events, setEvents] = useState(null)
  let startDate = new Date()
  let endDate = new Date()
  // Starting from Monday to show the full week
  startDate.setDate(startDate.getDate() - startDate.getDay() - 1)
  endDate.setDate(endDate.getDate() + 14)

  let createDate = new Date()
  createDate.setDate(startDate.getDate() - 7)
  startDate = startDate.toISOString()

  const API_KEY = process.env.GATSBY_GOOGLE_API_KEY
  const CLIENT_ID = process.env.GATSBY_GOOGLE_CLIENT_ID

  // function handleResize() {
  //   setView(window.innerWidth <= 600 ? "dayGridDay" : "dayGridWeek")
  // }

  let views = {
    center: "timeGridTwoDay,timeGridWeek,dayGridMonth",
  }

  let daysView = {
    timeGridTwoDay: {
      type: "timeGrid",
      duration: { days: 1 },
      buttonText: "Nap",
    },
  }

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize)
  //   return () => window.removeEventListener("resize", handleResize)
  // }, [])

  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.defer = true
    script.src = "https://apis.google.com/js/api.js"

    document.body.appendChild(script)

    script.addEventListener("load", () => {
      console.log("LOADED...")
      if (window.gapi) handleClientLoad()
    })
  }, [])

  const handleClientLoad = () => {
    console.log("AUTHENTICATE")
    window.gapi.load("client:auth2", initClient)
  }

  const openSignInPopup = () => {
    window.gapi.auth2.authorize(
      { client_id: CLIENT_ID, scope: SCOPES },
      res => {
        if (res) {
          if (res.access_token)
            localStorage.setItem("access_token", res.access_token)

          // Load calendar events after authentication
          console.log("UpcomingEvents...")
          window.gapi.client.load("calendar", "v3", listUpcomingEvents)
        }
      }
    )
  }

  const initClient = () => {
    console.log("INITIALISED...")
    console.log(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${API_KEY}&orderBy=startTime&singleEvents=true`
    )

    if (!localStorage.getItem("acces      openSignInPopup()s_token")) {
      openSignInPopup()
    } else {
      // Get events if access token is found without sign in popup
      openSignInPopup()
      fetch(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${API_KEY}&orderBy=startTime&singleEvents=true`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then(res => {
          // Check if unauthorized status code is return open sign in popup
          if (res.status !== 401) {
            return res.json()
          } else {
            localStorage.removeItem("access_token")

            openSignInPopup()
          }
        })
        .then(data => {
          if (data?.items) {
            setEvents(formatEvents(data.items))
          }
        })
    }
  }

  const listUpcomingEvents = () => {
    window.gapi.client.calendar.events
      .list({
        calendarId: "kovacsg76@gmail.com",
        timeMin: startDate,
        showDeleted: false,
        singleEvents: false,
      })
      .then(
        function (response) {
          let events = response.result.items

          if (events.length > 0) {
            setEvents(formatEvents(events))
          }
        },
        error => console.log("HIBA,error")
      )
  }

  const formatEvents = list => {
    console.log(list)
    list.map(item =>
      console.log(
        "Compare ****************",
        item.created,
        createDate,
        createDate.getTime()
      )
    )

    const nList = list
      .filter(item => item.created > createDate)
      .map((el, ind) => ({
        created: el.created,
        summary: el.summary,
        start:
          el.status === "cancelled" ? "" : el.start.dateTime || el.start.date,
      }))
    console.log("Unsorted list...", nList)
    console.log(
      "Sorted list...",
      nList.sort((a, b) => console.log(a.start - b.start))
    )
    let newList = list.filter(item => item.status !== "cancelled")
    return newList.map(item => ({
      title: item.summary === "SZABAD" ? "SZABAD" : "FOGLALT",
      start: item.start.dateTime || item.start.date,
      end: item.end.dateTime || item.end.date,
      display: "block",
      border: "5px solid red",

      textColor: "white",
      backgroundColor: item.summary.includes("SZABAD")
        ? "green"
        : item.summary.includes("GYEREK")
        ? "#EC5800"
        : item.summary.includes("VINCO")
        ? "#E30B5C"
        : item.summary.includes("ABS")
        ? "#FF3131"
        : item.summary.includes("ONLINE")
        ? "#E0115F"
        : item.summary.includes("SZEMÉLYI")
        ? "#FA8072"
        : item.summary.includes("BIA")
        ? "#FF2400"
        : item.summary.includes("IDŐKITÖLTŐ")
        ? "#8B0000"
        : "#808080",
    }))
  }

  return (
    <Layout>
      <Seo title="Foglaltsági naptár" />
      <section id="container">
        <h1>Foglaltsági naptár</h1>
      </section>
      <div id="Calendar">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView={view}
          events={events}
          eventColor="black"
          firstDay="1"
          locales={huLocale}
          locale="hu"
          slotMinTime="07:00"
          slotMaxTime="19:00"
          expandRows="true"
          headerToolbar={views}
          views={daysView}
          contentHeight="auto"
        />
      </div>
      <Contact />
    </Layout>
  )
}
